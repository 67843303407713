import React from 'react'
import {Link} from 'gatsby'
import Layout from "../components/layout"
import get from 'lodash/get'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import moment from 'moment'
import { StaticQuery, graphql } from "gatsby"

class BlogArchive extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title');
    const posts = get(this, 'props.data.allMarkdownRemark.edges');
    var currentYear = "";

    return (
        <StaticQuery
            query={graphql`
query ArchiveQuery {
  site {
    siteMetadata {
      title
    }
  }
  allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC}) {
    edges {
      node {
        excerpt
        fields {
          slug
        }
        frontmatter {
          title
          date
          slug
        }
      }
    }
  }
}
`}
            render={data => (
                <Layout location={this.props.location}>
      <div>
        <Helmet title={siteTitle}>
            <link href="https://paul.querna.org/atom.xml" rel="alternate" title="Paul Querna's Journal" type="application/atom+xml" />
        </Helmet>

        <div className="page-header">
          <h1>Post Archive</h1>
        </div>

        {posts.map(post => {
          if (post.node.path !== '/404/') {
            const year = moment(post.node.frontmatter.date).format("YYYY");
            let yearMarker;
            if (currentYear != year) {
              currentYear = year
              yearMarker = (
                <div className="row">
                  <div className="col">
                    <h2 key={year}>{year}</h2>
                  </div>
                </div>
              )
            }

            const title = get(post, 'node.frontmatter.title') || post.node.frontmatter.slug
            const dateFmt = moment(post.node.frontmatter.date).format("MMMM Do, YYYY");

            return (
              <div key={post.node.fields.slug} className="container">
                {yearMarker}
                <div className="row">
                  <div className="col-md-11 offset-md-1">
                    <h4>
                      <Link to={post.node.fields.slug} >
                        {post.node.frontmatter.title}
                      </Link>
                    </h4>
                    <small>{dateFmt}</small>
                    <p dangerouslySetInnerHTML={{ __html: post.node.excerpt }} />
                  </div>
                </div>
              </div>
            )
          }
        })}
      </div>
        </Layout>
                )}
                />
    )
  }
}

BlogArchive.propTypes = {
  route: PropTypes.object,
}

export default BlogArchive